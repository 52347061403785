import { bleskHeader } from '@default/scripts/modules/blesk-header';

/**
 * Init scriptu pro hlavicku Blesku:
 *    - otevirani velke navigace
 *    - prepnuti do sticky stavu
 *    - prizpusobeni poctu polozek u sticky menu
 *    - posouvani secondary menu (jestli existuje) do sticky headeru
 */
bleskHeader();

/**
 * Modul ipadChangeViewport zajistuje na iPadu upravu meta tagu viewport a pridani provizornich CSS
 *
 * Dynamicky import modulu, pokud je detekován iPad
 */
const dynamicIpadChangeViewport = async () => {
    if (document.documentElement.clientWidth <= 1024 && navigator.userAgent.match(/iPad/i)) {
        const { ipadChangeViewport } = await import('./modules/ipad-change-viewport');

        ipadChangeViewport();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicIpadChangeViewport();
};

loadDynamicImports();
